<template>
  <div class="with-nav-bar">
    <van-nav-bar
      left-text="返回"
      title="分润设置"
      :right-text="
        state.loading || state.error ? '' : !state.edit ? '更新' : '保存'
      "
      :left-arrow="true"
      :fixed="true"
      @click-left="$router.back()"
      @click-right="editform"
    />
    <van-loading v-if="state.loading" class="mt-3 text-center" vertical
      >加载中</van-loading
    >
    <van-empty
      v-else-if="state.error"
      image="error"
      :description="state.error_text"
    />
    <div v-else-if="!state.edit">
      <van-cell title="平台分润比例" :value="`${state.r0}%`" />
      <van-cell title="一级代理比例" :value="`${state.r1}%`" />
      <van-cell title="二级代理比例" :value="`${state.r2}%`" />
      <van-cell title="特殊分润A" :value="`${state.r3}%`" />
      <van-cell title="特殊分润B" :value="`${state.r4}%`" />
    </div>
    <div v-else>
      <van-field
        label="平台分润比例"
        v-model="state.r0"
        type="number"
        maxlength="5"
        disabled
      >
        <template #extra>%</template>
      </van-field>
      <van-field
        label="一级代理比例"
        v-model="state.r1"
        type="number"
        maxlength="5"
      >
        <template #extra>%</template>
      </van-field>
      <van-field
        label="二级代理比例"
        v-model="state.r2"
        type="number"
        maxlength="5"
      >
        <template #extra>%</template>
      </van-field>
      <van-field
        label="特殊分润A"
        v-model="state.r3"
        type="number"
        maxlength="5"
      >
        <template #extra>%</template>
      </van-field>
      <van-field label="特殊分润B" v-model="state.r4" maxlength="5">
        <template #extra>%</template>
      </van-field>
    </div>
  </div>
</template>

<script>
import { onMounted, reactive } from "vue";
import { useRoute, useRouter } from "vue-router";
import { Empty, Field, Toast } from "vant";
import { getProfitSharing, setProfitSharing } from "@/api/station.service";
export default {
  components: {
    [Field.name]: Field,
    [Empty.name]: Empty,
  },

  setup() {
    const route = useRoute();
    const router = useRouter();
    const state = reactive({
      station_id: 0,
      edit: false,
      loading: true,
      error: false,
      error_text: null,
    });

    const loadForm = async (station_id) => {
      try {
        const { result } = await getProfitSharing(station_id);
        for (const key in result) {
          state[key] = result[key];
        }
        state.loading = false;
      } catch (error) {
        const { data, statusText } = error;
        const msg = data && data.msg ? data.msg : statusText;
        Toast({ message: msg ? msg : "前端脚本异常", position: "bottom" });
        if (!msg) console.log(error);
        state.error = true;
        state.error_text = msg;
        state.loading = false;
      }
    };

    const saveForm = async () => {
      Toast.loading({
        message: "保存中",
        duration: 0,
        forbidClick: true,
      });
      try {
        await setProfitSharing({
          station_id: state.station_id,
          r0: state.r0,
          r1: state.r1,
          r2: state.r2,
          r3: state.r3,
          r4: state.r4,
        });
        state.edit = false;
        Toast.success("设置成功");
      } catch (error) {
        const { data, statusText } = error;
        const msg = data && data.msg ? data.msg : statusText;
        Toast({ message: msg ? msg : "前端脚本异常", position: "bottom" });
        if (!msg) console.log(error);
      }
    };

    const editform = () => {
      if (state.loading || state.error) {
        return;
      }

      if (!state.edit) {
        state.edit = true;
        return;
      }

      if (
        parseFloat(state.r0) +
          parseFloat(state.r1) +
          parseFloat(state.r2) +
          parseFloat(state.r3) +
          parseFloat(state.r4) !=
        100
      ) {
        Toast("分润比例总和必须为100%");
        return;
      }
      saveForm();
    };

    onMounted(() => {
      const { station_id } = route.query;
      if (!station_id) {
        Toast.fail("参数错误");
        router.back();
      } else {
        state.station_id = station_id;
        loadForm(station_id);
      }
    });

    return { state, editform };
  },
};
</script>
